import React, { useState, useEffect } from "react"
import { motion, AnimatePresence, useViewportScroll, useSpring, useTransform } from "framer-motion"
import SeniorPHPJobRequirements from "./SeniorPHPJobRequirements"
import JuniorPHPJobDesc from "./JuniorPHPJobDesc"
import JuniorPHPJobResponsibilities from "./JuniorPHPJobResponsibilities"
import SeniorPHPJobNiceToHave from "./SeniorPHPJobNiceToHave"
import Benefits from "./Benefits"
import ContactFormJobs from "./ContactFormJobs"
import { useTranslation } from 'react-i18next';
import Modal from "./Modal"
import JobTwoDesc from "./job-two/JobTwoDesc";
import JobTwoResponsibilities from "./job-two/JobTwoResponsibilities";
import JobTwoRequirements from "./job-two/JobTwoRequirements";
import JobTwoNiceToHave from "./job-two/JobTwoNiceToHave";
import JobFooter from "./JobFooter";
const hideNavItemsVariant = {
    opened: {
      opacity: 0,
      y: "-100%",
      transition: {
        duration: 0.5,
        ease: "easeInOut"
      }
    },
    closed: {
      opacity: 1,
      y: "0%",
      transition: {
        delay: 1.1,
        duration: 0.5,
        ease: "easeInOut"
      }
    }
  }

  const mobileMenuVariant = {
    opened: {
      y: "0%",
      transition: {
        delay: 0.15,
        duration: 1.1,
        ease: [0.74, 0, 0.19, 1.02]
      }
    },
    closed: {
      y: "-100%",
      transition: {
        delay: 0.35,
        duration: 0.63,
        ease: [0.74, 0, 0.19, 1.02]
      }
    }
  }

  const fadeInVariant = {
    opened: {
      opacity: 1,
      transition: {
        delay: 1.2
      }
    },
    closed: { opacity: 0 }
  }

  const ulVariant = {
    opened: {
      transition: {
        delayChildren: 1,
        staggerChildren: 0.18
      }
    },
    closed: {
      transition: {
        staggerChildren: 0.06,
        staggerDirection: -1
      }
    }
  }

  const liVariant = {
    opened: {
      opacity: 1,
      y: "0%",
      transition: {
        duration: 0.65,
        ease: "easeOut"
      }
    },
    closed: {
      opacity: 0,
      y: "100%",
      transition: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  }

const JobTwo = ({ closeFn = () => null, open = false }) => {
  const target = React.createRef();
  const [isComplete, setIsComplete] = useState(false);
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });
  const { t } = useTranslation();
  useEffect(() => yRange.onChange(v => setIsComplete(v >= 1)), [yRange]);
  const MOBILE_NAV_ITEMS = [
    {
      id: 0,
      navTitle: t('jobs_desc'),
      component: JobTwoDesc
    },
    {
      id: 1,
      navTitle: t('jobs_resp'),
      component: JobTwoResponsibilities
    },
    {
      id: 2,
      navTitle: t('jobs_req'),
      component: JobTwoRequirements
    },
    {
      id: 3,
      navTitle: t('jobs_nicetohave'),
      component: JobTwoNiceToHave
    },
    {
      id: 4,
      navTitle: t('jobs_benefits'),
      component: Benefits
    },
    {
      id: 5,
      component: JobFooter
    },
    // {
    //   id: 5,
    //   navTitle: t('fill_form'),
    //   component: ContactFormJobs
    // }
  ]
  return (
    <AnimatePresence>
      <Modal open={open}>
    <main className="container">
      <motion.nav
        initial="closed"
        animate={open ? "opened" : "closed"}
        className="navich"
      >
        <motion.div variants={mobileMenuVariant} className="mobile-menu"> 
        
          <motion.button
            variants={fadeInVariant}
            onClick={closeFn}
            className="close-button"
          >
            {t('close')}
          </motion.button>
          
          <motion.ul variants={ulVariant} >
            
            <div className="job-title"><span>{t("job_two_title")}</span></div>
            
            {MOBILE_NAV_ITEMS.map(navItem => (
              
              <motion.li key={navItem.id}>
                <motion.div variants={liVariant} className="job-subtitle">{navItem.navTitle}</motion.div>
                <motion.div><navItem.component /></motion.div>
              </motion.li>
            ))} 
           
          </motion.ul>
        </motion.div>
      </motion.nav>
    </main>
    </Modal>
    </AnimatePresence>
    
  )
}

export default JobTwo
