import React from 'react'
import {useTranslation} from 'react-i18next';

const JobOneNiceToHave = () => {
    const {t} = useTranslation();

    return (
        <div className="job-content">
            <div>
                <ul>
                    <li>
                        {t('job_one_nicetohave_1')}
                    </li>
                    <li>
                        {t('job_one_nicetohave_2')}
                    </li>
                    <li>
                        {t('job_one_nicetohave_3')}
                    </li>
                    <li>
                        {t('job_one_nicetohave_4')}
                    </li>
                    <li>
                        {t('job_one_nicetohave_5')}
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>
                        {t('job_one_nicetohave_6')}
                    </li>
                    <li>
                        {t('job_one_nicetohave_7')}
                    </li>
                    <li>
                        {t('job_one_nicetohave_8')}
                    </li>
                    <li>
                        {t('job_one_nicetohave_9')}
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default JobOneNiceToHave