import React from 'react'

const SeniorPHPJobRequirements = () => {
  return (
    <div className="job-content">
        <div>
          <ul>
            <li>
              This individual must be able to work 
              from our Banja Luka, Bosnia office
            </li>
            <li>
              3+ years of experience working as a developer in PHP
            </li>
            <li>
              Knowledge of Laravel or Symfony PHP framework
            </li>
            <li>
              Experience in MySQL database
            </li>
          </ul>
        </div>
        <div>
            <ul>
              <li>
                Solid knowledge of the Linux operating system
              </li>
              <li>
                Solid knowledge of Git
              </li>
              <li>
                Problem-solving aptitude
              </li>
              <li>
                Good communication skills
              </li>
            </ul>
        </div>
    </div>
  )
}

export default SeniorPHPJobRequirements