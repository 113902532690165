import React from 'react'
import { useTranslation } from 'react-i18next';

const JobOneResponsibilities = () => {
    const {t} = useTranslation();
    return (
        <div className="job-content">
            <div>
                <ul>
                    <li>
                        {t('job_one_responsibilities_1')}
                    </li>
                    <li>
                        {t('job_one_responsibilities_2')}
                    </li>
                    <li>
                        {t('job_one_responsibilities_3')}
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>
                        {t('job_one_responsibilities_4')}

                    </li>
                    <li>
                        {t('job_one_responsibilities_5')}
                    </li>
                    <li>
                        {t('job_one_responsibilities_6')}
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default JobOneResponsibilities