import React, { useState, useRef } from "react"
import { motion, AnimatePresence, useViewportScroll, useSpring, useTransform, useScroll } from "framer-motion"
import Benefits from "./Benefits"
import ContactFormJobs from "./ContactFormJobs"
import { useTranslation } from 'react-i18next';
import Modal from "./Modal"
import JobOneDesc from "./job-modal-react-js-junior/JobOneDesc";
import JobOneResponsibilities from "./job-modal-react-js-junior/JobOneResponsibilities";
import JobOneRequirements from "./job-modal-react-js-junior/JobOneRequirements";
import JobOneNiceToHave from "./job-modal-react-js-junior/JobOneNiceToHave";
import JobFooter from "./JobFooter";
const hideNavItemsVariant = {
    opened: {
      opacity: 0,
      y: "-100%",
      transition: {
        duration: 0.5,
        ease: "easeInOut"
      }
    },
    closed: {
      opacity: 1,
      y: "0%",
      transition: {
        delay: 1.1,
        duration: 0.5,
        ease: "easeInOut"
      }
    }
  }

  const mobileMenuVariant = {
    opened: {
      y: "0%",
      transition: {
        delay: 0.15,
        duration: 1.1,
        ease: [0.74, 0, 0.19, 1.02]
      }
    },
    closed: {
      y: "-100%",
      transition: {
        delay: 0.35,
        duration: 0.63,
        ease: [0.74, 0, 0.19, 1.02]
      }
    }
  }

  const fadeInVariant = {
    opened: {
      opacity: 1,
      transition: {
        delay: 1.2
      }
    },
    closed: { opacity: 0 }
  }

  const ulVariant = {
    opened: {
      transition: {
        delayChildren: 1,
        staggerChildren: 0.18
      }
    },
    closed: {
      transition: {
        staggerChildren: 0.06,
        staggerDirection: -1
      }
    }
  }

  const liVariant = {
    opened: {
      opacity: 1,
      y: "0%",
      transition: {
        duration: 0.65,
        ease: "easeOut"
      }
    },
    closed: {
      opacity: 0,
      y: "100%",
      transition: {
        duration: 0.25,
        ease: "easeInOut"
      }
    }
  }

const JobOne = ({ closeFn = () => null, open = false }) => {
  const target = React.createRef();
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ container: ref });
  // const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1]);
  // const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });
  const { t } = useTranslation();
  
  const MOBILE_NAV_ITEMS = [
    {
      id: 0,
      navTitle: t('jobs_desc'),
      component: JobOneDesc
    },
    {
      id: 1,
      navTitle: t('jobs_resp'),
      component: JobOneResponsibilities
  
    },
    {
      id: 2,
      navTitle: t('jobs_req'),
      component: JobOneRequirements
  
    },
    {
      id: 3,
      navTitle: t('jobs_nicetohave'),
      component: JobOneNiceToHave
  
    },
    {
      id: 4,
      navTitle: t('jobs_benefits'),
      component: Benefits
  
    }, 
    {
      id: 5,
      // navTitle: t('fill_form'),
      component: JobFooter
    },
    // {
    //   id: 5,
    //   navTitle: t('fill_form'),
    //   component: ContactFormJobs
    // }
  ];
  
  return (
    <AnimatePresence>
      <Modal open={open}>
        <main className="container">
          <motion.nav
            initial="closed"
            animate={open ? "opened" : "closed"}>
            <motion.div className="mobile-menu" variants={mobileMenuVariant}> 
              <motion.button
                variants={fadeInVariant}
                className="close-button"
                onClick={closeFn}>
                {t('close')}
              </motion.button>
              <motion.ul variants={ulVariant} ref={ref}>
                <div className="job-title"><span>{t("job_one_title")}</span></div>
                {MOBILE_NAV_ITEMS.map(navItem => (
                  <motion.li key={navItem.id}>
                    <motion.div variants={liVariant} className="job-subtitle">{navItem.navTitle}</motion.div>
                    <motion.div><navItem.component /></motion.div>
                  </motion.li>))
                } 
              </motion.ul>
            </motion.div>
          </motion.nav>
        </main>
      </Modal>
    </AnimatePresence>
  )
}

export default JobOne
