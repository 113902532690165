import React from 'react'
import { useTranslation } from 'react-i18next';

const JobTwoDesc = () => {
    const {t} = useTranslation();
    return (
        <>
            <div className="job-content first">
                <div>
                    {t('job_two_desc_1')}
                </div>
                <div>
                    {t('job_two_desc_2')}
                </div>
            </div>
            <div className="job-content first">
                <div>
                    {t('job_two_desc_3')}
                </div>
            </div>
        </>

    )
}

export default JobTwoDesc