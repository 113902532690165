import React, {useState, useRef} from 'react'
import { FooterContainer, FooterBg } from './FooterElements'
import SocialMedia from '../SocialMedia/SocialMedia'
import PerformcbWhite from '../PerformcbWhite';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import ParallaxElement from '../ParallaxElement';

const Footer = () => {
  const [showResults, setShowResults] = useState(false);
  const onClick = () => setShowResults(true);
  const {t} = useTranslation();
  const box = useRef(null);
  const box2 = useRef(null);

  setTimeout(() => {
    setShowResults(false);
  }, 4000);

  return (
    <>
        <FooterContainer>
          <FooterBg>
            {/* <img src={require('../../images/Group999.png')} alt='mySvgImage' className="mobileFooter"
            style={{width: '100%',
              }}
            /> */}
            <ParallaxElement dataPercent="-15" dataDirection="x">
              <div ref={box} className="box">
                <img src={require('../../images/oblacic.png')} />
              </div>
            </ParallaxElement>
            <ParallaxElement dataPercent="15" dataDirection="x">
              <div ref={box2} className="box right">
                <img src={require('../../images/malioblakdesno.png')} />
              </div>
            </ParallaxElement>
          </FooterBg>
          <div className="footer-wrapper">
              <div className="info">
                <p>
                  Svetozara Markovica 5, {t('entrance')}
                </p>
                <p>
                  {t('floor')}
                </p>
                <p>
                  Banja Luka, 78 000
                </p>
                <p>
                  {t('BH')}
                </p>
                <div className="hovertext-container">
                  <p className="hovertext-p1">
                    contactbay42@performcb.com
                  </p>
                  <CopyToClipboard text="contactbay42@performcb.com">
                    <p className="hovertext-p2" id="copy" onClick={onClick}>
                    { showResults ? "copied" : "copy to clipboard" }
                    </p>
                  </CopyToClipboard>
                </div>
                <p>
                  <div style={{color: '#ffc500'}} onClick={() => window.open('tel:12345')}>+ 387 66 412 342</div>
                </p>
                {/* <button onClick={handleClick}>Copy the data</button> */}
              </div>
              <div>
                <SocialMedia />
              </div>
              <div className="partner-logo-mobile">
                <a onClick={() => window.open('https://www.performcb.com')} target="_blank" rel="noopener noreferrer">
                  <PerformcbWhite></PerformcbWhite>
                </a>
              </div>
            </div>
        </FooterContainer>
    </>
  )
}

export default Footer