import React from 'react'
import { useTranslation } from 'react-i18next';

const JobTwoRequirements = () => {
    const {t} = useTranslation();
    return (
        <div className="job-content">
            <div>
                <ul>
                    <li>
                        {t('job_two_requirements_1')}
                    </li>
                    <li>
                        {t('job_two_requirements_2')}
                    </li>
                    <li>
                        {t('job_two_requirements_3')}
                    </li>
                    <li>
                        {t('job_two_requirements_4')}
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>
                        {t('job_two_requirements_5')}
                    </li>
                    <li>
                        {t('job_two_requirements_6')}
                    </li>
                    <li>
                        {t('job_two_requirements_7')}
                    </li>
                    <li>
                        {t('job_two_requirements_8')}
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default JobTwoRequirements