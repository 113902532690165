import React from 'react'
import { useTranslation } from 'react-i18next';

const JobFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="job-content">
        <div style={{width: '100%'}}>
            <div className={'job-subtitle'}>{t('job_footer_text')}</div>
            <a style={{color: 'white'}} href="mailto: contactbay42@performcb.com">contactbay42@performcb.com</a>
        </div>
    </div>
  )
}

export default JobFooter