import * as React from "react"
// used https://react-svgr.com/playground/ to convert svgs to comp.
const ArrowIcon = (props) => (
  <svg width={27} height={27} xmlns="http://www.w3.org/2000/svg" {...props} className="arrow">
    <title>{"north_east_black_24dp"}</title>
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M9 0v3.6h11.862L0 24.462 2.538 27 23.4 6.138V18H27V0z"
      />
  </svg>
)

export default ArrowIcon;
