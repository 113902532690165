import React from 'react'
import { useTranslation } from 'react-i18next';

const JobTwoNiceToHave = () => {
    const {t} = useTranslation();

    return (
        <div className="job-content">
            <div>
                <ul>
                    <li>
                        {t('job_two_nicetohave_1')}
                    </li>
                    <li>
                        {t('job_two_nicetohave_2')}
                    </li>
                    <li>
                        {t('job_two_nicetohave_3')}
                    </li>
                </ul>
            </div>
            {/*<div>*/}
            {/*    <ul>*/}
            {/*        <li>*/}
            {/*            {t('job_two_nicetohave_4')}*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            {t('job_two_nicetohave_5')}*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
        </div>
    )
}

export default JobTwoNiceToHave