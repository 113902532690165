import React from 'react'
import { useTranslation } from 'react-i18next';

const Benefits = () => {
  const { t } = useTranslation();
  return (
    <div className="job-content">
        <div>
          <ul>
            <li>
            {t('benefits_1')}
            </li>
            <li>
            {t('benefits_2')}
            </li>
            <li>
            {t('benefits_3')}
            </li>
            <li>
            {t('benefits_4')}
            </li>
            <li>
            {t('benefits_5')}
            </li>
            <li>
            {t('benefits_6')}
            </li>
          </ul>
        </div>
        <div>
            <ul>
              <li>
              {t('benefits_7')}
              </li>
              <li>
              {t('benefits_8')}
              </li>
              <li>
              {t('benefits_9')}
              </li>
              <li>
              {t('benefits_10')}
              </li>
              <li>
              {t('benefits_11')}
              </li>
              <li>
              {t('benefits_12')}
              </li>
            </ul>
        </div>
    </div>
  )
}

export default Benefits