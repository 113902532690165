import React from 'react'
import { useTranslation } from 'react-i18next';

const JobOneRequirements = () => {
    const {t} = useTranslation();
    return (
        <div className="job-content">
            <div>
                <ul>
                    <li>
                        {t('job_one_requirements_1')}
                    </li>
                    <li>
                        {t('job_one_requirements_2')}
                    </li>
                    <li>
                        {t('job_one_requirements_3')}
                    </li>

                </ul>
            </div>
            <div>
                <ul>
                    <li>
                        {t('job_one_requirements_4')}
                    </li>
                    <li>
                        {t('job_one_requirements_5')}
                    </li>
                    <li>
                        {t('job_one_requirements_6')}
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default JobOneRequirements